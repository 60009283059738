<template>
    <div>
        <div class="container-exercises">
            <div class="exercise-container">
                <div class="exercise-container__title">
                    <h1 class="body-semibold-18 color-base">{{ getSelectedExercise?.institution?.name || "Stoodi" }}</h1>
                </div>
                <div class="exercise-container__description">
                    <span :inner-html.prop="getSelectedExercise?.text" class="body-regular-16 color-base"></span>
                </div>
                <div class="exercise-container__question">
                    <Radio name="answer" :id="`opt-${item.letter}`" v-for="(item, index) in getSelectedExercise?.answers"
                        :key="index" :value="item.id" @change="handleSelect(item)"
                        :checked="selectedAnswer?.id === item.id">
                        <template v-slot:default>
                            <div :class="[
                                'body-semibold-16',
                                'exercise-container__label',
                                selectedAnswer?.id === item.id && wrongAnswer !== null ? wrongAnswer ? 'wrong' : 'correct' : '',
                                seeCorrectAnswer && item.letter === getSelectedExercise?.correct_choice ? 'correct' : ''
                            ]">
                                <span>{{ item.letter }}.</span>
                                <span :inner-html.prop="item.text"></span>
                            </div>
                        </template>
                    </Radio>
                </div>
            </div>
            <Alert type="warning" v-if="error" :description="error" icon="danger" />
            <Divider />
            <div class="exercise-footer">
                <div :class="['exercise-footer__feedback', wrongAnswer ? 'wrong' : 'correct']">
                    <span class="exercise-footer__feedback__title">
                        {{ feedback_title }}
                    </span>
                    <div>
                        <span class="exercise-footer__feedback__description">
                            {{ feedback_description }}
                        </span>
                        <span class="exercise-footer__feedback__more body-regular-14" v-if="wrongAnswer === true"
                            @click="toggleCorrectAnswer">
                            {{ seeCorrectAnswer ? 'Ocultar' : 'Ver' }} resposta correta
                        </span>
                    </div>
                </div>
                <div class="exercise-footer__actions">
                    <ButtonPlay type="normal" @click="openVideo"
                        v-if="selectedAnswer && wrongAnswer !== null && (getSelectedExercise?.video_media_stream)">
                        <IconSax name="video-square" />
                        Ver resolução
                    </ButtonPlay>
                    <ButtonPlay type="normal" @click="reset" v-if="selectedAnswer && wrongAnswer === true">
                        Refazer
                    </ButtonPlay>
                    <ButtonPlay type="normal" @click="checkAnswer" v-if="wrongAnswer === null">
                        Verificar resposta
                    </ButtonPlay>
                    <ButtonPlay v-if="nextButton" type="outline" @click="goToNext">
                        Próximo
                        <IconSax name="arrow-right-1" />
                    </ButtonPlay>
                </div>
            </div>
            <Divider />
            <div class="report-problem">
                <Link label="Reportar um problema" icon="danger" to="/report-problem" />
            </div>
        </div>
        <LightboxVideo :source="getSelectedExercise?.video_media_stream?.embed_path" v-if="isVideoOpen" @close="openVideo"
            height="480px" />
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    props: {
        getSelectedExercise:{
            type: Object
        },
        nextButton: {
            type: Boolean,
            default: true
        }
    },
    components: {
        LightboxVideo: () =>
            import(
                "@/components/LightboxVideo.vue"
            ),
        Alert: () =>
            import(
                "@/components/common/Alert.vue"
            ),
        Link: () =>
            import(
                "@/components/base/Link.vue"
            ),
        Divider: () =>
            import(
                "@/components/common/Divider.vue"
            ),
        ButtonPlay: () =>
            import(
                "@/components/common/ButtonPlay.vue"
            ),
        Radio: () =>
            import(
                "@/components/base/Radio.vue"
            ),
    },
    computed: {
        ...mapGetters({
            getExercises: "exercises/getExercises",
        }),
        exercisesAllowed() {
            if (
                this.$store.getters['user/metasSite'] &&
                this.$store.getters['user/metasSite'].exercises_allowed
            ) {
                return this.$store.getters['user/metasSite'].exercises_allowed === "1";
            } else return null;
        },
    },
    async created() {
        if (!this.exercisesAllowed) {
            this.$router.push({
                name: "Home",
            });
        }
        await this.actionSetIsLoading(true);
        const area = this.$route.params.area;
        const subCategory = this.$route.params.subCategory;
        const exercise = this.$route.params.exercise;
        await this.actionFetchExercise([area, subCategory, exercise]);
        await this.actionFetchExercisesAreas(area);
        await this.actionSetSubCategory(subCategory);
        await this.actionFetchExercisesByCategory({ area, subCategory });
        await this.actionSetIsLoading(false);
    },
    data() {
        return {
            error: null,
            selectedAnswer: null,
            wrongAnswer: null,
            feedback_title: "",
            feedback_description: "",
            isVideoOpen: false,
            seeCorrectAnswer: false,
            refresh: false,
        }
    },
    methods: {
        ...mapActions({
            actionSetIsLoading: "config/actionSetIsLoading",
            actionFetchExercisesByCategory: "exercises/actionFetchExercisesByCategory",
            actionSetSubCategory: "exercises/actionSetSubCategory",
            actionFetchExercisesAreas: "exercises/actionFetchExercisesAreas",
            actionFetchExercise: "exercises/actionFetchExercise",
            submitAnswer: "exercises/submitAnswer",
        }),
        toggleCorrectAnswer() {
            this.seeCorrectAnswer = !this.seeCorrectAnswer;
        },
        reset() {
            this.selectedAnswer = null;
            this.wrongAnswer = null;
            this.feedback_title = "";
            this.feedback_description = "";
            this.seeCorrectAnswer = false;
            this.error = null;
        },
        openVideo() {
            this.isVideoOpen = !this.isVideoOpen;
        },
        async onSubmit() {
            const exercise = this.getSelectedExercise;
            exercise.area_url = this.$route.params.area;
            exercise.subject_url = this.$route.params.subCategory;
            await this.submitAnswer({
                exercise,
                answer: this.selectedAnswer.id,
            })
            this.refresh = true
        },
        checkAnswer() {
            if (!this.selectedAnswer) {
                this.error = "Escolha uma das alternativas.";
                return;
            }
            const isCorrect = this.getSelectedExercise.correct_choice === this.selectedAnswer.letter;
            this.wrongAnswer = !isCorrect;
            this.feedback_title = isCorrect ? "Resposta correta" : "Resposta incorreta";
            this.feedback_description = isCorrect ? "Muito bem! Parabéns pelo acerto" : "Não foi dessa vez!";
            this.onSubmit();
        },
        handleSelect(item) {
            this.reset();
            this.selectedAnswer = item;
            this.selectedAnswer.isCorrect = this.getSelectedExercise.correct_choice === item.letter;
        },
        async goToNext() {
            this.reset();
            const area = this.$route.params.area;
            const subCategory = this.$route.params.subCategory;
            const exercise = this.$route.params.exercise;

            const index = this.getExercises.exercises.data.findIndex((item) => item.url === exercise);
            const nextExercise = this.getExercises.exercises.data[index + 1];
            if (nextExercise) {
                this.$router.push({
                    name: "exercise",
                    params: {
                        area,
                        subCategory,
                        exercise: nextExercise.url,
                    },
                });
            } else {
                if (this.getExercises.exercises.current_page < this.getExercises.exercises.last_page) {
                    this.actionFetchExercisesByCategory({ area, subCategory, page: this.getExercises.exercises.current_page + 1 })
                        .then(() => {
                            const nextExercise = this.getExercises.exercises.data[0];
                            this.$router.push({
                                name: "exercise",
                                params: {
                                    area,
                                    subCategory,
                                    exercise: nextExercise.url,
                                },
                            });
                        })
                }
                else
                    this.$router.push({
                        name: "exercise_list",
                        params: {
                            area,
                            subCategory,
                        },
                    });
            }
            await this.actionFetchExercise([area, subCategory, nextExercise.url]);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.container-exercises {
    padding-top: 1.5rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 8.31rem;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }
}

.exercise-container {
    @include flex-column;
    gap: 1rem;
    margin-top: 1.5rem;

    &__title {
        @include flex-row;
        gap: 0.5rem;
    }

    &__question {
        @include flex-column;
        gap: 1rem;
        margin-bottom: 2.25rem;

        label {
            margin-bottom: 0;
        }
    }

    .correct {
        [data-theme="dark"] & {
            background-color: #60B5274D;
        }

        background-color: var(--feedback-success-100);
    }

    .wrong {
        [data-theme="dark"] & {
            background-color: #FF39324D;
        }

        background-color: var(--feedback-error-100);
    }

    &__label {
        @include flex-row;
        width: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        align-items: center;

        ::v-deep p {
            margin: 0;
        }
    }

    &__label span:first-child {
        width: 2.5rem;
    }
}

.exercise-footer {
    @include flex-row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
        @include flex-column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__actions {
        @include flex-row;
        justify-content: flex-end;
        align-items: center;
        padding: 1.5rem 1rem;
        gap: 1rem;

        @media screen and (max-width: 768px) {
            padding: 0rem 1rem 1.5rem 1rem;
        }
    }

    .wrong {
        color: var(--feedback-error-500);
    }

    .correct {
        color: var(--feedback-success-500);
    }

    &__feedback {
        @include flex-column;
        gap: 0.5rem;
        padding: 1.5rem 1rem;
        border-radius: 0.5rem;

        &__title {
            font: var(--body-semibold-14);
        }

        &__description {
            font: var(--body-regular-14);
        }

        &__more {
            color: var(--neutral-gray-500) !important;
            cursor: pointer;
        }
    }
}

.report-problem {
    margin-top: 1rem;
}
</style>
  